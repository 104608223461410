// Don't add to this, these legacy pages are a terrible way to do react.  Home, Management are
import { COMPONENTS } from '../constants';
import * as NotificationStore from '../components/notification/configureStore';
import * as CashieringStore from '../components/cashiering/configureStore';
import * as SCRAStore from '../components/scra/configureStore';


const root = document.getElementById('root');

const component = root.getAttribute('data-component');

export const getInitialState = () => {
    switch (component) {
        case COMPONENTS.NotificationPage:
            return NotificationStore.getInitialState();
        case COMPONENTS.CashieringPage:
            return CashieringStore.getInitialState();
        case COMPONENTS.SCRAPage:
            return SCRAStore.getInitialState();
        default:
            return {};
    }
}

export const getReducers = () => {

    switch (component) {
        case COMPONENTS.NotificationPage:
            return NotificationStore.getReducers();
        case COMPONENTS.CashieringPage:
            return CashieringStore.getReducers();
        case COMPONENTS.SCRAPage:
            return SCRAStore.getReducers();
        default:
            return {};
    }

}

export const persist = (store) => {
    if (component === COMPONENTS.NotificationPage) {
        NotificationStore.watchStore(store)
    }

    if (component === COMPONENTS.CashieringPage) {
        CashieringStore.watchStore(store)
    }

    if (component === COMPONENTS.SCRAPage) {
        SCRAStore.watchStore(store)
    }

}