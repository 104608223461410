
export const API_URL = '/api';

export const COMPONENTS = {
	LoginPage: 'LoginPage',
	HomePage: 'HomePage',
	ManagementPage: 'ManagementPage',
	NotificationPage: 'NotificationPage',
	CashieringPage: 'CashieringPage',
	ReportsPage: 'ReportsPage',
	QueueSetupPage: 'QueueSetupPage',
	SCRAPage: 'SCRAPage',
}