export const actionTypes = {
  CHANGE_PAGE: 'SCRA:CHANGE_PAGE',
  RESET_PAGE: 'SCRA:RESET_PAGE',
  GET_SEARCH_RESULTS_SUCCESS: 'SCRA:GET_SEARCH_RESULTS_SUCCESS',
  CHANGE_PAGING_SIZE_GRID: 'SCRA:CHANGE_PAGING_SIZE_GRID',
  CHANGE_SORT_GRID: 'SCRA:CHANGE_SORT_GRID',
  TOGGLE_CHECK_BOX: 'SCRA:TOGGLE_CHECK_BOX',
  TOGGLE_SELECT_ALL: 'SCRA:TOGGLE_SELECT_ALL',
  TOGGLE_COLUMN_CHECKBOX: 'SCRA:TOGGLE_COLUMN_CHECKBOX',
  UPDATE_STATUS_OPTION_FOR_VOUCHER_SUCCESS: 'SCRA:UPDATE_STATUS_OPTION_FOR_VOUCHER_SUCCESS',
  TOGGLE_LOADING_INDICATOR_ON: 'SCRA:TOGGLE_LOADING_INDICATOR_ON',
  TOGGLE_LOADING_INDICATOR_OFF: 'SCRA:TOGGLE_LOADING_INDICATOR_OFF',
  UPDATE_LAST_VIEWED: 'SCRA:UPDATE_LAST_VIEWED',
  RESIZE_COLUMN: 'SCRA:RESIZE_COLUMN'
};
