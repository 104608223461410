import { SCRASearchCertificateDownloadLink } from '../scra-pdf-download-link';
export const getSCRAColumns = () => {
    return ([
        {
            accessor: 'fileName',
            Header: 'File Name',
            show: true,
            inColumnPicker: true,
            index: 0,
            headerClassName: 'textalign-left'
        },
        {
            accessor: 'ssn',
            Header: 'SSN',
            show: true,
            inColumnPicker: true,
            index: 1,
            headerClassName: 'textalign-left',
            Cell: (row) => {
                return row.value ? ('XXX-XX-' + row.value.substr(-4)) : '';
            }
        },
        {
            accessor: 'dateOfBirth',
            Header: 'Date of Birth',
            show: true,
            inColumnPicker: true,
            index: 2,
            headerClassName: 'textalign-left',
            Cell: (row) => {
                return row.original.dateOfBirthDisplay;
            }
        },
        {
            accessor: 'lastName',
            Header: 'Last Name',
            show: true,
            inColumnPicker: true,
            index: 3,
            headerClassName: 'textalign-left'
        },
        {
            accessor: 'firstName',
            Header: 'First Name',
            show: true,
            inColumnPicker: true,
            index: 4,
            headerClassName: 'textalign-left'
        },
        {
            accessor: 'activeDutyStatusDate',
            Header: 'Active Duty Status Date',
            show: true,
            inColumnPicker: true,
            index: 5,
            headerClassName: 'textalign-left',
            Cell: (row) => {
                return row.original.activeDutyStatusDateDisplay;
            }
        },
        {
            accessor: 'onActiveDutyOnActiveDutyStatusDate',
            Header: 'Active Duty on Active Duty Status Date',
            show: true,
            inColumnPicker: true,
            index: 6,
            headerClassName: 'textalign-left'
        },
        {
            accessor: 'searchDate',
            Header: 'SCRA Request Search Date',
            show: true,
            inColumnPicker: true,
            index: 7,
            headerClassName: 'textalign-left',
            Cell: (row) => {
                return row.original.searchDateDisplay;
            }
        },
        {
            accessor: 'reportDate',
            Header: 'Report Date',
            show: true,
            inColumnPicker: true,
            index: 8,
            headerClassName: 'textalign-left',
            Cell: (row) => {
                return row.original.reportDateDisplay;
            }
        },
        {
            accessor: 'certificateID',
            Header: 'Certificate ID',
            show: true,
            inColumnPicker: true,
            index: 9,
            headerClassName: 'textalign-left',
            Cell: (row) => {
                const value: String = row.value;
                return row.original.certificatePathExists ? <SCRASearchCertificateDownloadLink fileUploadId={row.original.fileUploadId} certificateId={value} certificatePath={row.original.certificatePath} /> : row.value;
            }
        },
    ]);
};
