import React from "react";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import { useUser } from "../../store/user";

const NavEvents = ({ allowMonitoring, allowClaims }) => {

    return (
        <NavDropdown eventKey={2} title="Events" id="menu-events">
            {allowMonitoring && <MenuItem href="/Event?QueueNameId=-3&runNow=false&collapseParms=false#clearprevious" eventKey={2.1}>Docket</MenuItem>}
        </NavDropdown>
    );
}

/// TODO: Get the case detail to show upnp
const NavSearch = ({ advSearch, advSearchFor }) => {

    return (
        <NavDropdown eventKey={3} title="Case Search" id="menu-case-search">
            <MenuItem eventKey={3.1} href="/Case/MyCases">My Cases</MenuItem>
            {advSearch && <MenuItem href="/Case" eventKey={3.2}>All Bankruptcy Cases</MenuItem>}
            <MenuItem href="/Case/AllCasesByCaseNumber" eventKey={3.3}>All Cases by Case Number</MenuItem>
            {advSearch && advSearchFor && <MenuItem href="/Case/ProceedingAttributesSearch" eventKey={3.4}>Proceeding Attributes</MenuItem>}
        </NavDropdown>
    );
}

const NavAdmin = () => {
    const { myAacerAuthId, mngAuthId, allowMonitoring, allowClaims } = useUser();
    return (
        <NavDropdown eventKey={4} title="Admin" id="menu-admin">
            <MenuItem eventKey={4.1} href="/Admin/UserSettings/ChangeEmail">User Settings</MenuItem>
            <MenuItem eventKey={4.2} href="/Admin/Preferences/CaseSearch">Preferences</MenuItem>
            {myAacerAuthId >= 100 && <MenuItem href="/Admin/MyAACER/Files" eventKey={4.3}>My AACER</MenuItem>}
            {//allowPOC && isAdmin &&
                //    <MenuItem href="/Admin/ClaimFiling/Settings" eventKey={4.4}>Claim Filing</MenuItem>
            }
            
            {mngAuthId > 50 &&
                <MenuItem href="/Admin/Management/" eventKey={4.5}>Management</MenuItem>}
            {(allowMonitoring || allowClaims) &&
                <MenuItem href="/Admin/Queues/AuditReport" eventKey={4.6}>Queues</MenuItem>}
        </NavDropdown>
    );
}

const NavNotification = ({ allowNotification, allowFullSocialSearch }) => {

    return (
        <NavDropdown eventKey={5} title="Notifications" id="menu-notifications">
            {allowNotification && <MenuItem href="/Notification" eventKey={5.1}>Queues</MenuItem>}
            {allowFullSocialSearch && <MenuItem href="/Notification#/fss" eventKey={5.2}>Full Social Search</MenuItem>}
        </NavDropdown>
    )
}

const NavReports = () => {

    return (
        <NavDropdown eventKey={9} title="Reports" id="menu-reports">
            <MenuItem href="/Reports/Reports" eventKey={9.1}>Settings </MenuItem>}
        </NavDropdown>
    )
}


const Menu = () => {
    const {
        allowMonitoring, allowClaims, subTypeId, allowFullSocialSearch, allowNotification, isClaimFilingUser,
        allowCashiering, reportsAuthId, advSearch, advSearchFor,allowSCRA
    } = useUser();


    return (
        <Navbar fluid>
            <Navbar.Header>
                <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
                <Nav>
                    <NavItem eventKey={1} href="/Home">Home</NavItem>
                    {(allowMonitoring || allowClaims) &&
                        <NavEvents allowClaims={allowClaims} allowMonitoring={allowMonitoring} />
                    }
                    {subTypeId !== 2 &&
                        <NavSearch advSearch={advSearch} advSearchFor={advSearchFor} />
                    }
                    <NavAdmin  />
                    {(allowNotification || allowFullSocialSearch) &&
                        <NavNotification allowNotification={allowNotification} allowFullSocialSearch={allowFullSocialSearch} />
                    }
                    {//isClaimFilingUser &&
                       // <NavItem eventKey={6} href="/ClaimFiling">Claim Filing</NavItem>
                    }
                    {allowCashiering &&
                       <NavItem eventKey={7} href="/Cashiering">Cashiering</NavItem>
                    }
                    {allowSCRA &&                      <NavItem eventKey={10} href="/SCRA">SCRA</NavItem>
                    }
                    {(reportsAuthId === 150 || reportsAuthId === 200) &&
                        <NavReports />
                    }

                    <NavItem eventKey={8} href="/Help">Help</NavItem>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
}

export default Menu;