import { getSCRAColumns } from '../scra/SCRAResults/components/gridSettings/scra-columns';
import {
    getGridPersitance
} from '../shared/genericDataGrid';
import { reducer as scraReducer } from './SCRAResults/reducers/scra-reducer';
import { searchFilterReducer } from '../shared/searchFilter';

const pageState = window.__SCRA_PAGE_STATE__ ? JSON.parse(window.__SCRA_PAGE_STATE__) : {};
const initialState = {};

export const ReducerFilters = {
    SCRA_SUMMARY_REDUCER_NAME: 'scraSummaryReducer',
    SCRA_SUMMARY_FILTER_NAME: 'scraSummaryFilterReducer',
};

const scraSummaryPersitance = getGridPersitance(ReducerFilters.SCRA_SUMMARY_REDUCER_NAME);
const scraResultsGridSettings = scraSummaryPersitance.getStorage();
initialState[ReducerFilters.SCRA_SUMMARY_REDUCER_NAME] = {
    items: [],
    totalRecords: 0,
    page: 0,
    pageSize: scraResultsGridSettings ? scraResultsGridSettings.pageSize : 10,
    sortBy: scraResultsGridSettings ? scraResultsGridSettings.sortBy :
        [{
            id: '',
            desc: false
        }],
    columns: getSCRAColumns(),
    reportDates: pageState.reportDates,
    fileNames: pageState.fileNames,
    defaultColumns: getSCRAColumns(),
    dateRetrieved: 'No data retrieved',
    selectAllChecked: false,
    loading: true,
    resized: [],
    lastViewedRow: scraResultsGridSettings ? scraResultsGridSettings.lastViewedRow : ''
};

if (pageState.params && Object.keys(pageState.params).length > 0) {

    let params = pageState.params;
    Object.keys(pageState.params).forEach(x => {
        switch (x.toLowerCase()) {
            case "reportDate":
                initialState.reportDate = [Date(params.reportDates)];
                return;
            case "fileids":
                initialState.fileIds = [params.fileNames];
                return;
        }
    });
}


initialState[ReducerFilters.SCRA_SUMMARY_FILTER_NAME] = {
    showSaveSearch: false,
    searchFilter: {},
    isCollapsed: true
};

export const getInitialState = () => {
    return initialState;
}

export const getReducers = () => {
    return {
        scraSummaryReducer: scraReducer,
        scraSummaryFilterReducer: searchFilterReducer(ReducerFilters.SCRA_SUMMARY_FILTER_NAME),
    }
}

export const watchStore = (store) => {
    scraSummaryPersitance.setStorage(store);
}
